<!--
this component is used in both My Leads and Available Leads
-->
<template>
    <q-submenu :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    data() {
        return {
            title: "My Account",
            titlelink: [{
                label: "",
                link: "",
            }],
            buttons: [{},

            ],
            submenu: [{
                label: "Help",
                to: "/support"
            }]
        }
    },
    computed: {},
    components: {
        QSubmenu
    },
    methods: {},
}

</script>
<style>
</style>
